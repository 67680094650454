import React from "react"

const Careers = () => {
  return (
    <section className="careers">
      <h1>Interested in applying for a role with us?</h1>
      <h1>
        Please send your resume and cover letter to{" "}
        <a href="mailto:info@dobacor.com">info@dobacor.com</a>
      </h1>
    </section>
  )
}

export default Careers
