import React from "react"

import Profile from "./Profile.component"

const ProfileContainer = ({
  reverseOrder,
  companyDescription,
  companyParagraph1,
  companyParagraph2,
  companyParagraph3,
  portrait,
  name,
  position,
  position2,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
  paragraph5,
}) => {
  return (
    <section
      className={`profile-container ${reverseOrder ? "reverseOrder" : null}`}
    >
      <Profile
        reverseOrder={reverseOrder}
        companyDescription={companyDescription}
        companyParagraph1={companyParagraph1}
        companyParagraph2={companyParagraph2}
        companyParagraph3={companyParagraph3}
        portrait={portrait}
        name={name}
        position={position}
        position2={position2}
        paragraph1={paragraph1}
        paragraph2={paragraph2}
        paragraph3={paragraph3}
        paragraph4={paragraph4}
        paragraph5={paragraph5}
      />
      <div className="line-container">
        <div className="line-fg" />
        <div className="line-bg" />
      </div>
    </section>
  )
}

export default ProfileContainer
