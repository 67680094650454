import React from "react"

import GatsbyImage from "../gatsbyImage/GatsbyImage.component"

const Profile = ({
  companyDescription,
  companyParagraph1,
  companyParagraph2,
  companyParagraph3,
  portrait,
  name,
  position,
  position2,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
  paragraph5,
}) => {
  return (
    <section className="profile">
      {companyDescription && (
        <div className="paragraph-container">
          <p>{companyParagraph1}</p>
          <p>{companyParagraph2}</p>
          <p>{companyParagraph3}</p>
        </div>
      )}
      <div className="portrait-container">
        <GatsbyImage image={portrait.childImageSharp.fluid} />
      </div>
      <h1>{name}</h1>
      <h3>{position}</h3>
      <h3>{position2}</h3>
      <div className="paragraph-container">
        <p>{paragraph1}</p>
        <p>{paragraph2}</p>
        <p>{paragraph3}</p>
        <p>{paragraph4}</p>
        <p>{paragraph5}</p>
      </div>
    </section>
  )
}

export default Profile
