import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import PageHeadline from "../components/pageHeadline/PageHeadline.component"
import ProfileContainer from "../components/aboutPage/ProfileContainer.component"
import Careers from "../components/aboutPage/Careers.component"
import SectionHeader from "../components/sectionHeader/SectionHeader.component"
import ProjectsContainer from "../components/projectsContainer/ProjectsContainer.component"

const About = () => {
  const portraits = useStaticQuery(graphql`
    {
      AM: file(
        sourceInstanceName: { eq: "portraits" }
        relativePath: { eq: "AM.JPG" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      MW: file(
        sourceInstanceName: { eq: "portraits" }
        relativePath: { eq: "MW.JPG" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="About Us" />
      <PageHeadline headline1="About" headline2="Us" />
      <ProfileContainer
        companyDescription
        companyParagraph1="Dobacor is a privately-held international property development and management company focusing on ultra-luxury residential and hotel projects globally."
        companyParagraph2="Dobacor’s U.S. headquarters is based in Los Angeles with active development projects and properties located in Los Angeles and New York’s most exclusive neighborhoods."
        companyParagraph3="Dobacor collaborates with Pritzker Prize-winning Architects, world-class Interior Designers & virtual reality teams to deliver some of the most exceptional developments in the U.S."
        portrait={portraits.AM}
        name="Anthony McKibben"
        position="Vice President"
        position2="Development Director"
        paragraph1="Anthony is an internationally experienced development director & architectural designer based in Los Angeles, California. Anthony oversees the various components of the development process and is responsible for managing Dobacor’s high-end development projects in the U.S."
        paragraph2="Anthony has 16 years of extensive international experience working in a diverse background of Property Development, Project Management & Architectural Design projects, ranging from large-scale, mixed-used developments, to shopping centers, hotels, casinos, resorts & custom-designed, luxury residential estates, with a particular focus on projects located throughout Asia, Australia, and the U.S."
        paragraph3="Anthony was raised cross-culturally throughout Asia and Australia, graduating from The Queensland University of Technology in Brisbane, Australia with a Bachelor’s Degree in Architectural Design. Prior to this, Anthony studied Property Development, and Urban Design & Master Planning at The University of Queensland in Brisbane, Australia."
        paragraph4="Specializing in unique development projects of the highest quality, Anthony focuses on developing & designing minimalist luxury beach resorts, private island villas, boutique hotels and residences internationally."
        paragraph5="Anthony’s streamlined approach to efficient development processes and high-quality design, results in the delivery of creatively successful, timely and profitable projects."
      />
      <ProfileContainer
        reverseOrder
        portrait={portraits.MW}
        name="Melissa Wang"
        position="PROJECT COORDINATOR"
        position2=""
        paragraph1="Having grown up in Shanghai, Melissa developed from a young age a passion for the real estate industry in the ever-changing metropolitan landscape."
        paragraph2="Melissa has a diverse project coordination experience in development, investment and property management. She assists Project Managers and Project teams in the development and implementation of tracking, coordination, and procurement systems tailored to meet the unique needs of each project."
        paragraph3="Melissa graduated with a bachelor’s degree in Psychology from the University of Southern California, and holds a Project Management certificate from Cornell University. She is also a licensed real estate agent."
      />
      <Careers />
      <SectionHeader headline="Featured projects" />
      <ProjectsContainer max={2} />
    </>
  )
}

export default About
